import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Loading from './Pages/Loading';
import { useNavigate } from 'react-router-dom';
import { User, onAuthStateChanged, onIdTokenChanged } from "firebase/auth";
import { FBuser, auth, refreshSignedUser, signedUser } from './Helpers/Auth';
import Login from './Pages/Login';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Layout } from './Pages/Layout';
import WelcomeScreen from './Pages/SubPages/WelcomeScreen';
import DataCenters from './Pages/SubPages/DataCenters';
import DataCenterDetails from './Pages/SubPages/DataCenterDetails';
import NotFound from './Pages/SubPages/NotFound';
import Users, { AuthUser, createAuthUser } from './Pages/SubPages/Users';
import axios from 'axios';
import Engineers from './Pages/SubPages/Engineers';
import TelegramBots from './Pages/SubPages/TelegramBots';
import SearchPage from './Pages/SubPages/SearchPage';
import SettingsPage from './Pages/other/settings/SettingsPage';
import MachineOnlineStatus from './Pages/other/settings/Online/MachineOnlineStatus';
import MachineBillingInfo from './Pages/other/settings/billing/MachineBillingInfo';
import MachineBillingShared from './Pages/other/settings/billing/MachineBillingShared';
import HealthCheckPage from "./Pages/SubPages/Health/HealthCheckPage";
import Report from './Pages/SubPages/Report/Report';

export const API_PATH = "segments"
export const DEFAULT_LANDING_PAGE = "/DataCenters"

export const appContext = React.createContext("");

function App() {
  const navigate = useNavigate();
  const [page, setPage] = useState("");
  const [authStatus, setAuthStatus] = useState<boolean | null>(null);
  const [authUpdate, setAuthUpdate] = useState<any>(null);
  const [authUser, setAuthUser] = useState<AuthUser | null>(null);

  useEffect(() => {
    document.title = "Segments cloud computing..."
    onAuthStateChanged(auth, validateAuthToken);
    onIdTokenChanged(auth, validateAuthToken)
  }, [])
  useEffect(() => {
    getUserInfo()
    let idInterval = setInterval(() => { refreshSignedUser(() => { }, true); }, 2000000);
    return () => clearInterval(idInterval);
  }, [authUpdate])
  function validateAuthToken(user: User | null) {
    console.log("token refresh")
    if (user) {
      signedUser(processFBuser)
    } else {
      processFBuser(null)
    }
  }
  function getUserInfo() {
    axios.post('/' + API_PATH + "/auth/", {})
      .then(r => { setAuthUser(createAuthUser(r.data)); setAuthStatus(true) })
      .catch(e => { console.log(e) })
  }
  useEffect(() => {
    console.log(authUser)
  }, [authUser])
  function processFBuser(user: FBuser | null) {
    if (user == null) {
      axios.defaults.headers.common['userId'] = ""
      axios.defaults.headers.common['userFbId'] = ""
      axios.defaults.headers.common['token'] = ""
      setAuthStatus(false)
    } else {
      axios.defaults.headers.common['userId'] = user.email
      axios.defaults.headers.common['userFbId'] = user.id
      axios.defaults.headers.common['token'] = user.token
      console.log("token updated")
      setAuthUpdate((new Date()).getTime())
    }
  }
  return (
    <appContext.Provider value={page}>
      <Routes>
        <Route path="/sharedBilling" element={<MachineBillingShared />} />
        {(authStatus == null) && (<Route path="*" element={<Loading />} />)}
        {(authStatus == false) && (<Route path="*" element={<Login />} />)}
        {(authStatus == true) && (authUser != null) && (<>
          {(authUser.authRole === "ADMIN") && (<>
            <Route path="/Search" element={<Layout children={<SearchPage user={authUser} />} user={authUser} />} />
            <Route path="/Telegram" element={<Layout children={<TelegramBots />} user={authUser} />} />
            <Route path="/Engineers" element={<Layout children={<Engineers />} user={authUser} />} />
            <Route path="/DataCenters" element={<Layout children={<DataCenters />} user={authUser} />} />
            <Route path="/DataCenter/:id" element={<Layout children={<DataCenterDetails />} user={authUser} />} />
            <Route path="/Users" element={<Layout children={<Users />} user={authUser} />} />
            <Route path="/Settings" element={<Layout children={<SettingsPage />} user={authUser} />} />
            <Route path="/MachineOnlineStatus" element={<Layout children={<MachineOnlineStatus />} user={authUser} />} />
            <Route path="/MachineBillingInfo" element={<Layout children={<MachineBillingInfo />} user={authUser} />} />
            <Route path="/Health" element={<Layout  children={<HealthCheckPage/>} user={authUser}/>}/>
            <Route path="/Report" element={<Layout  children={<Report/>} user={authUser}/>}/>
            <Route path="/" element={<Layout children={<DataCenters />} user={authUser} />} />
            <Route path="*" element={<Layout children={<NotFound />} user={authUser} />} />
          </>)}
          {(authUser.authRole === "CLIENT") && (<>
            <Route path="/" element={<Layout children={<SearchPage user={authUser} />} user={authUser} />} />
            <Route path="/Search" element={<Layout children={<SearchPage user={authUser} />} user={authUser} />} />
          </>)}
          <Route path="*" element={<Layout children={<NotFound />} user={authUser} />} />
        </>)}
      </Routes>
    </appContext.Provider>
  );
}

export default App;
