import React, { useState } from "react";
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  TextField, Button, Stack, Typography, TableSortLabel
} from "@mui/material";
import axios from "axios";
import { API_PATH } from "../../../App";

interface UptimeData {
  macId: string;
  workerId: string;
  centerName: string;
  runDate: string;
  hours: number;
  model: string;
  serialNo: string;
  userName: string;
}

const columns: { key: keyof UptimeData; label: string }[] = [
  { key: "macId", label: "Mac ID" },
  { key: "workerId", label: "Worker ID" },
  { key: "userName", label: "User Name" },
  { key: "centerName", label: "Center Name" },
  { key: "model", label: "Model" },
  { key: "serialNo", label: "Serial Number" },
  { key: "hours", label: "Hours" },
];

const Report: React.FC = () => {
  const [data, setData] = useState<UptimeData[]>([]);
  const [selectedDate, setSelectedDate] = useState<string>(
    new Date().toISOString().split("T")[0]
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<keyof UptimeData>("workerId");

  const fetchTableData = async () => {
    setLoading(true);

    try {
      const response = await axios.get<UptimeData[]>(`${API_PATH}/uptime/machineuptime?date=${selectedDate}`);
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    setLoading(false);
  };



  const downloadCSV = () => {
    if (data.length === 0) {
      alert("No data available to download.");
      return;
    }

    const headers = [...columns.map(col => col.label), "Uptime (%)"];

    const csvRows = [headers.join(",")];

    data.forEach(row => {
      const uptimePercentage = ((row.hours / 24) * 100).toFixed(2) + " %";
      const values = columns.map(col => `"${row[col.key]}"`);
      values.push(`"${uptimePercentage}"`);
      csvRows.push(values.join(","));
    });

    const blob = new Blob([csvRows.join("\n")], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `Machine_Uptime_Report_${selectedDate}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };


  const handleSort = (property: keyof UptimeData) => {
    setOrderBy(property);
    setOrder(orderBy === property && order === "asc" ? "desc" : "asc");
  };

  const sortedData = [...data].sort((a, b) => {
    if (a[orderBy] < b[orderBy]) return order === "asc" ? -1 : 1;
    if (a[orderBy] > b[orderBy]) return order === "asc" ? 1 : -1;
    return 0;
  });

  return (
    <Paper sx={{ padding: 2, margin: "auto" }}>
      <Stack direction="row" spacing={2} justifyContent="start" sx={{ marginBottom: 2 }}>
        <TextField
          label="Select Date"
          type="date"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
          sx={{ width: 200, height: 40, "& .MuiInputBase-root": { height: 40 } }}
          InputLabelProps={{ shrink: true }}
          disabled={loading}
        />
        <Button variant="contained" color="primary" onClick={fetchTableData} sx={{ height: 40 }} disabled={loading}>
          Search
        </Button>
        <Button variant="contained" color="secondary" onClick={downloadCSV} sx={{ height: 40 }} disabled={loading}>
          Download CSV
        </Button>
      </Stack>

      <TableContainer>
        {loading ? (
          <Typography align="center" sx={{ padding: 2 }}>
            Loading...
          </Typography>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                {columns.map(({ key, label }) => (
                  <TableCell
                    key={key}
                    sx={{
                      backgroundColor: "#f5f5f5",
                      fontWeight: "bold",
                      color: "#333",
                      padding: "10px",
                      textAlign: "start",
                      borderBottom: "2px solid #ccc",
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === key}
                      direction={orderBy === key ? order : "asc"}
                      onClick={() => handleSort(key)}
                      sx={{
                        "&.Mui-active": { color: "#1976d2" },
                        "& .MuiTableSortLabel-icon": { color: "#1976d2 !important" },
                      }}
                    >
                      {label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell sx={{ fontWeight: "bold" }}>Uptime (%)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.length > 0 ? (
                sortedData.map((row, index) => (
                  <TableRow key={index}>
                    {columns.map(({ key }) => (
                      <TableCell key={key}>{row[key]}</TableCell>
                    ))}
                    <TableCell>{((row.hours / 24) * 100).toFixed(2) + " %"}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length + 1} align="center">
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </Paper>
  );
};

export default Report;
