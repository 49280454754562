import { Box, Button, Grid, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { API_PATH } from "../../App";
import axios from "axios";
import AddIcon from '@mui/icons-material/Add';
import LoadingScreen from "../other/LoadingScreen";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import MachineInfoRow from "../other/MachineInfoRow";
import { Engineer, createEngineerFromObject } from "./Engineers";
import { createUserGroup, UserGroup } from "../other/settings/UserGroup/SettingsUserGroup";
import { sortMachineData } from "../../Helpers/MachineHelper";

export interface MachineHealth {
  hashRate: string | null;
  fanSpeed: string | null;
  temperature1: string | null;
  lastFetchTime: number | null;
  errorDetails: string | null;
  hasError: boolean | null;
  hasWarning: boolean | null;
  message: string | null;
}

export interface ModelConfig {
  id: string,
  model: string,
  internalModel: string,
  power: string
}
export function createHealth(n: MachineHealth | null) {
  return n;
}

export interface ErrorStatus {
  errorCode?: string | null,
  errorMessage?: string | null,
  errorShortMessage?: string | null,
  triggerTime?: string | null
}

export interface Machine {
  id: string;
  workerId: string;
  serialNo: string;
  model: string;
  url: string;
  centerId: string;
  disable: boolean;
  health: MachineHealth | null;
  configId: string;
  snoozeTill: number;
  snoozeInMinutes: number;
  message: string;
  isSnoozed: boolean;
  hashRate: number | null;
  macId: string;
  f2Pool: any;
  userGroup: UserGroup | null;
  hostingCost: string | null;
  machineConfig?: ModelConfig | null;
  repairing?: boolean;
  errorStatus?: ErrorStatus | null;
  additionalStatus?: string | null;
  additionalStatusTime?: number | null;
  virt?: boolean;
  "stratum"?: Stratum;
}
export interface Stratum {
  "coin": string | null,
  "pool": string | null,
  "stratumUrl": string | null,
  "canEnableVrt": boolean,
  "unexpectedConfig": boolean,
  "ssl": boolean
}
function createMachine(
  id: string,
  workerId: string,
  model: string,
  url: string,
  centerId: string,
  disable: boolean,
  health: MachineHealth | null,
  configId: string,
  snoozeTill: number,
  snoozeInMinutes: number,
  message: string,
  isSnoozed: boolean,
  hashRate: number | null,
  macId: string,
  f2Pool: any,
  userGroup: UserGroup | null,
  hostingCost: string | null,
  machineConfig?: ModelConfig | null,
  repairing?: boolean,
  errorStatus?: ErrorStatus | null,
  additionalStatus?: string | null,
  additionalStatusTime?: number | null,
  serialNo?: string,
  virt?:boolean,
): Machine {
  return { id: id, workerId: workerId, serialNo: serialNo ?? "", model, url, centerId, disable, health, configId, snoozeTill, snoozeInMinutes: snoozeInMinutes, message, isSnoozed, hashRate, macId, f2Pool, userGroup, hostingCost, machineConfig, repairing: repairing, errorStatus, additionalStatus, additionalStatusTime,virt:virt };
}

export function createMachineFromObject(n: any, dateNow: Date): Machine {
  return createMachine(n.id, n.workerId, n.model, n.url, n.centerId, n.disable, createHealth(n.health), n.configId, n.snoozedTill, 0, n.snoozedReason, !(dateNow > new Date(Number(n.snoozedTill))), n.hash_rate, n.macId, n.f2Pool, createUserGroup(n.userGroup), n.hostingCost, n.machineConfig, n.repairing, n.errorStatus, n.additionalStatus, n.additionalStatusTime, n.serialNo,n.virt);
}

export interface MachineConfig {
  id: string;
  model: string;
}
export function createMachineConfig(n: any
): MachineConfig {
  return n;
}
export function timeElapsed(n: MachineHealth | null) {
  if (n == null || n.lastFetchTime == null) return "";
  return timeElapsedSince(n.lastFetchTime, "ago")
}

export function timeElapsedSince(n: number | null, suffix?: string) {
  if (n == null) {
    return ""
  }
  var seconds = Math.floor((new Date().getTime() - n) / 1000);
  var interval = seconds / 31536000;
  if (interval > 1) {
    return Math.floor(interval) + " years " + (suffix ?? "");
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months " + (suffix ?? "");
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days " + (suffix ?? "");
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours " + (suffix ?? "");
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes " + (suffix ?? "");
  }
  return Math.floor(seconds) + " seconds " + (suffix ?? "");
}

interface Column {
  id: 'id' | 'workerId' | 'model' | 'url';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}
const columns: readonly Column[] = [
  //{ id: 'workerId', label: 'WorkerId', minWidth: 20 },
  { id: 'model', label: 'Model', minWidth: 20 },
  { id: 'url', label: 'URL', minWidth: 20 },

];
function DataCenterDetails() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = React.useState(true);
  const [datacenterName, setDatacenterName] = React.useState("");
  const [rows, setRows] = React.useState<Machine[]>([]);

  const [machineConfigs, setMachineConfigs] = React.useState<MachineConfig[]>([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(300);


  const [selectedMachine, setSelectedMachine] = React.useState<Machine | null>(null);

  const [siteEngineer, setSiteEnginner] = React.useState<Engineer | null>(null);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function getachineConfigs() {
    axios.get('/' + API_PATH + '/system/scanconfig')
      .then(function (response) {
        // handle success
        processMachineConfig(response.data)
        setIsLoading(false)
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
  }
  function processMachineConfig(data: any) {
    var newMachineConfigs: MachineConfig[] = []
    data.forEach((n: any) => {
      newMachineConfigs.push(createMachineConfig(n))
    });
    setMachineConfigs(newMachineConfigs);
  }
  function getMachines(id: string | undefined) {
    setIsLoading(true)
    axios.get('/' + API_PATH + '/datacenter/' + id + '/')
      .then(function (response) {
        // handle success
        processResponseDatacenterData(response.data)
        setIsLoading(false)
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setIsLoading(false)
      })
  }

  function processResponseDatacenterData(data: any) {
    let dateNow = new Date();
    setDatacenterName(data.centerName)
    setSiteEnginner(createEngineerFromObject(data.engineer))

    var result: Machine[] = []
    data.machineList.forEach((n: any) => {
      result.push(createMachineFromObject(n, dateNow))
    })
    setRows(sortMachineData(result))
  }


  function newMachine() {
    setSelectedMachine(createMachine("", "", "", "", id!, false, null, "", 0, 0, "", false, 0, "", null, null, null))
  }

  useEffect(() => {
    getachineConfigs()
    getMachines(id)
    let idInterval = setInterval(() => {
      axios.get('/' + API_PATH + '/datacenter/' + id + '/')
        .then((response) => { processResponseDatacenterData(response.data) })
    }, 15000);
    return () => clearInterval(idInterval);
  }, [])

  
  return (<Grid container >
    <Grid container style={{ paddingBottom: "20px" }} >
      <Grid item xs={9}>
        <Typography variant="h4" component="h2">
          #{id} {datacenterName}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Button variant="contained" style={{ float: "right", display: "none" }} endIcon={<AddIcon style={{ margin: "0" }} />} onClick={newMachine} >Add</Button>
      </Grid>
    </Grid>
    {(siteEngineer != null) && (
      <Grid container style={{ paddingBottom: "8px" }}>
        <Grid item xs={6}>
          <span>  <u>{siteEngineer?.name}</u> </span>
        </Grid>
        <Grid item xs={6} style={{ textAlign: "right" }}>
          <Box
            sx={{
              typography: 'body1',
              '& > :not(style) ~ :not(style)': {
                ml: 2,
              },
            }}
          >
            <Link target="_blank" href={"tel:" + siteEngineer.phone}><PhoneEnabledIcon /></Link>
            <Link target="_blank" href={"https://wa.me/" + siteEngineer.whatsapp.replace(/\D/g, '')}><WhatsAppIcon /></Link>
            <Link target="_blank" href={"tel:" + siteEngineer.botim_id} ><SmartToyIcon /></Link>
          </Box>

        </Grid>
      </Grid>
    )}
    <Grid container>
      <Grid item xs={12}>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{}}>
            <Table stickyHeader aria-label="sticky table" size="small" >
              <TableHead>
                <TableRow>
                  <TableCell >WorkerId</TableCell>
                  <TableCell width={1} style={{ padding: "0" }} >&nbsp;</TableCell>
                  <TableCell>Model / SN / MAC / Url</TableCell>
                  <TableCell>Hosting charge </TableCell>
                  <TableCell>Client</TableCell>
                  <TableCell >Settings</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return <MachineInfoRow data={row} key={row.id} refresh={() => { getMachines(id); }} machineConfigs={machineConfigs} showHistory={true} />
                  })}

              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[300, 600]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </Grid>
    {(isLoading) && (<LoadingScreen />)}
  </Grid>)
}

export default DataCenterDetails;
